#place-popup-container {
    position: absolute;
    background-color: white;
    height: auto;
    width: 15vw;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 0.8;
    cursor: pointer;
    border-radius: 4px;
}

.popup-visible {
    visibility: visible;
    opacity: 1;
}

.popup-hidden {
    animation: pin-popup-fade-out 0.5s linear forwards;
}

@keyframes pin-popup-fade-out {
    from { opacity: 1; }
    to { opacity: 0; visibility: hidden; }
}

#place-popup-shadow {
    -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.16));
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.16));
}

#place-popup .horizontal-line {
    background-color: gray;
    height: 1px;
    width: auto;
    margin: 8px 1px 4px 1px;
}

#place-popup .hovered {
    color: var(--main-color);
    border-color: var(--main-color);
    transition: linear 250ms;
}

.popup-location {
    font-family: var(--font-regular);
    font-size: var(--font-size-16);
    letter-spacing: var(--letter-spacing-0-16);
    line-height: var(--line-height-30);
}

.popup-footer {
    transition: linear 250ms;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: var(--font-regular);
    font-size: var(--font-size-16);
    letter-spacing: var(--letter-spacing-0-16);
    line-height: var(--line-height-30);
}

.popup-title {
    font-family: var(--font-regular);
    font-size: var(--font-size-26);
    letter-spacing: var(--letter-spacing-0-26);
    line-height: var(--line-height-30);
    margin-bottom: 0.2vw;
}

.popup-button {
    max-width: 14px;
    max-height: 14px;
    border: 2px solid black;
    border-radius: 14px;
}