#privacy-root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: calc(16000vw / 1920) calc(34000vw / 1920) calc(32000vw / 1920) calc(34000vw / 1920);
    font-family: var(--font-regular);
    font-size: var(--font-size-22);
    letter-spacing: var(--letter-spacing-0-22);
    line-height: var(--line-height-30);
    overflow-y: auto;
    user-select: text;
}

#privacy-root h1 {
    margin: 0 0;
    font-family: var(--font-medium);
    font-size: var(--font-size-100);
    letter-spacing: var(--letter-spacing-4);
    line-height: var(--line-height-94);
    text-transform: uppercase;
}

#privacy-root h2 {
    margin-top: calc(2400vw / 1920);
    font-family: var(--font-regular);
    font-size: var(--font-size-24);
    letter-spacing: var(--letter-spacing-0-24);
    line-height: var(--line-height-30);
}

#privacy-root h3 {
    margin-bottom: 2vw;
    font-family: var(--font-medium);
    font-size: var(--font-size-26);
    letter-spacing: var(--letter-spacing-1-04);
    line-height: var(--line-height-30);
}

#privacy-root .general {
    margin: calc(4000vw / 1920) 0;
}

#privacy-root .sections-wrapper button {
    display: inline-flex;
    align-items: center;
    font-family: var(--font-semibold);
    font-size: var(--font-size-38);
    letter-spacing: var(--letter-spacing-1-52);
    line-height: var(--line-height-40);
    text-transform: uppercase;
    border-bottom: black 1px solid;
    padding: calc(3500vw / 1920) 0;
    width: 100%;
    text-align: left;
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    cursor: pointer;
}

#privacy-root .sections-wrapper button:active {
    color: black;
}

#privacy-root .sections-wrapper button#expand-all {
    padding-left: calc(6000vw / 1920);
    border-bottom: none;
}

#privacy-root .accordeon .content {
    overflow: hidden;
    transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

#privacy-root .accordeon.collapsed .content {
    max-height: 0;
}

#privacy-root .accordeon.expanded .content {
    padding: 1em 0;
    max-height: auto;
}

#privacy-root #top-button {
    align-self: center;
    color: black;
    position: absolute;
    left: calc(17000vw / 1920);
    transform: translateX(-50%);
}

#privacy-root .button-icon:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    transition: linear 250ms;
}

#privacy-root .button-icon:active {
    color: var(--main-color-darker);
    border-color: var(--main-color-darker);
    transition: linear 250ms;
}

#privacy-root .button-icon {
    width: calc(8000vw / 1920);
    height: calc(8000vw / 1920);
    border-radius: 99999px;
    border-width: calc(300vw / 1920);
    border-color: black;
    border-style: solid;
    padding: 2px;
    margin-top: calc(1500vw / 1920);
}

#privacy-root .button-label {
    font-family: var(--font-medium);
    font-size: var(--font-size-24);
    letter-spacing: var(--letter-spacing-1-52);
    line-height: var(--line-height-40);
}

#privacy-root a {
    text-decoration: none;
    color: var(--main-color);
}

#privacy-root a:hover {
    text-decoration: none;
    color: var(--main-color);
}

#privacy-root a:active {
    text-decoration: none;
    color: var(--main-color-darker);
}

#privacy-root sup {
    vertical-align: super; 
    line-height: 0;
}

#privacy-root .expanded svg {
    width: calc(6000vw / 1920);
    height: calc(6000vw / 1920);
    transform: rotate(90deg);
    transition: ease-out 0.3s;
}

#privacy-root .collapsed svg {
    width: calc(6000vw / 1920);
    height: calc(6000vw / 1920);
    transition: ease-out 0.3s;
}