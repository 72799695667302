#news-root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

#news-root > .loading-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#news-root > .loading-panel > .loading-text {
  margin-top: 20px;
  color: var(--main-color);
  font-family: var(--font-medium);
  font-size: var(--font-size-24);
}

#news-root .foreground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-color);
  opacity: 0.8;
}

#news-root .cell:hover .foreground {
  opacity: 0;
  transition: opacity 0.5s;
}

#news-root .grid-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc(4480vw / 1920);
  overflow-y: auto;
  margin: 0;

  /* Hide scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS. */
  transform: translateZ(0);
}

#news-root .grid-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

#news-root .grid-list > .load-more {
  margin: 0.8vw 0;
  display: inline-block;
}

#news-root .gray-text {
  font-size: var(--font-size-20);
  font-family: var(--font-italic);
  line-height: var(--line-height-24);
  color: gray;
}

#news-root .horiz-mid {
  display: block;
  margin: 0 auto;
  text-align: center;
}

#news-root .grid-list .cell {
  position: relative;
  color: white;
  float: left;
  margin: 4px;
  overflow: hidden;
  cursor: pointer;
}

#news-root .grid-list .cell > .small-cell {
  position: absolute;
  bottom: 2.3vw;
  left: 2vw;
  right: 2vw;
  top: 1.5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

#news-root .grid-list .cell > .featured-cell {
  position: absolute;
  bottom: 2.3vw;
  left: 2vw;
  right: 2vw;
}

#news-root sup {
  vertical-align: super;
  line-height: 0;
}

#news-root .grid-list .cell .cell-title {
  font-size: var(--font-size-38);
  font-family: var(--font-extrabold);
  line-height: var(--line-height-48);
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  margin-bottom: 0.8vw;
  padding-top: 0.8vw;
}

#news-root .grid-list .cell .cell-title sup {
  font-size: var(--font-size-30);
}

#news-root .grid-list .cell .featured-cell-title {
  font-size: var(--font-size-50);
  font-family: var(--font-extrabold);
  line-height: var(--line-height-64);
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  margin-bottom: 0.8vw;
  padding-top: 0.8vw;
}

#news-root .grid-list .cell .featured-cell-title sup {
  font-size: var(--font-size-40);
}

#news-root .grid-list .cell .cell-subtitle {
  font-size: var(--font-size-26);
  font-family: var(--font-semibold);
  line-height: var(--line-height-34);
  white-space: pre-line;
}
