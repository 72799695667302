#background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: gray;
}

#password-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
    font-family: 'Sen-Regular';
    max-width: 750px;
}

#password-popup .p-input {
    margin: 32px 0 8px 0;
    font-size: large;
    border-color: gray;
    border-radius: 5px;
    width: 200px;
    height: 30px;
}

#password-popup .p-button {
    font-size: large;
    border-radius: 0;
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    width: 208px;
    height: 36px;
}