#article-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-bottom: calc(4480vw / 1920);
    background-color: white;
    overflow-y: auto;
    user-select: text;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#article-root::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
}

#article-root img {
    width: 100%;
    height: 40vw;
    object-fit: cover;
}

#article-root h1 {
    font-size: var(--font-size-100);
    font-family: var(--font-thin);
    line-height: var(--line-height-108);
}

#article-root h2 {
    font-size: var(--font-size-70);
    font-family: var(--font-medium);
    line-height: var(--line-height-74);
    margin: 1vw 0;
}

#article-root h3 {
    font-size: var(--font-size-38);
    font-family: var(--font-semibold);
    line-height: var(--line-height-40);
}

#article-root .date {
    margin: 0;
    font-size: var(--font-size-26);
    font-family: var(--font-medium);
}   

#article-root .author {
    margin: 0;
    font-size: var(--font-size-24);
    font-family: var(--font-regular);
}

#article-root .categ {
    margin: 0;
    font-size: var(--font-size-26);
    font-family: var(--font-extrabold);
    line-height: var(--line-height-30);
}

#article-root .article-name {
    margin: 0;
    font-size: var(--font-size-26);
    font-family: var(--font-semibold);
    line-height: var(--line-height-30);
}

#article-root .title-row .art-desc>.categ {
    color: var(--main-color);
}

#article-root>.article-content {
    margin: 0 5.2vw;
}

#article-root .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-100);
}

#article-root .title-row sup {
    vertical-align: super; 
    font-size: var(--font-size-70);
    line-height: 0;
}

#article-root .title-row .art-desc sup {
    vertical-align: super; 
    font-size: var(--font-size-20);
    line-height: 0;
}

#article-root .title-row .art-desc {
    min-width: 15vw;
    text-align: end;
}

#article-root .content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#article-root .content-row>.content-text {
    font-size: var(--font-size-20);
    font-family: var(--font-regular);
    padding-right: 15vw;
}

#article-root .content-row>.content-text a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}

#article-root .content-row>.content-text img {
    object-fit: contain;
}

#article-root .related {
    min-width: 20vw;
    max-width: 22vw;
}

#article-root .related .categ-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#article-root .related .categ-title>.categ-title-back-button {
    width: 1.5vw;
    height: 1.5vw;
    cursor: pointer;
    margin-right: 0.4vw;
}

#article-root .related .categ-title>.categ-title-back-button:hover {
    color: var(--main-color);
}

#article-root .related .categ-title>.categ-title-back-button:active {
    color: var(--main-color-darker);
}

#article-root .related .horiz-arrangement {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0.5vw 0 4vw 0;
}

#article-root .related .vert-line {
    width: 1px;
    background-color: black;
}

#article-root .related .rel-item {
    color: black;
    margin: 1vw 1.4vw;
    cursor: pointer;
}

#article-root .related .rel-item:hover {
    color: var(--main-color);
}

#article-root .related .rel-item:active {
    color: var(--main-color-darker);
}

#article-root .related .rel-item>.categ {
    margin-top: 1.5vw;
}

#article-root .related .rel-item>.title {
    margin: 0;
    font-size: var(--font-size-30);
    font-family: var(--font-regular);
    line-height: var(--line-height-34);
}

#article-root .related .rel-item>.desc {
    margin: 0;
    font-size: var(--font-size-16);
    font-family: var(--font-medium);
}

#article-root .up-text {
    font-size: var(--font-size-24);
    font-family: var(--font-medium);
    margin-bottom: 1.5vw;
}

#article-root .next-post-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    color: black;
    padding: 5.5vw;
    cursor: pointer;
}

#article-root .next-post-row:hover {
    color: var(--main-color);
}

#article-root .next-post-row:active {
    color: var(--main-color-darker);
}

#article-root .circled-icon {
    border: 1px solid;
}

#article-root .up-icon {
    width: 4.2vw;
    height: 4.2vw;
    border-radius: 2.1vw;
}

#article-root .next-icon {
    width: 6.7vw;
    height: 6.7vw;
    border-radius: 3.35vw;
    margin-left: 10vw;
}