#letter-root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: calc(10000vw / 1920);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  font-family: var(--font-regular);
  font-size: var(--font-size-24);
  letter-spacing: var(--letter-spacing-0-24);
  line-height: var(--line-height-34);
  user-select: text;
}

#letter-root .main-content {
  padding-top: 8.049vw;
  margin-top: -8.049vw;
  background: white
    linear-gradient(
      319deg,
      #e9d8eb66 0%,
      #e2d7e93d 0%,
      #e7d8ea66 0%,
      #e9d8eb8d 31%,
      #79c4c712 60%,
      #ffffff00 100%,
      #e9d8eb66 100%
    )
    0% 0% no-repeat padding-box;
}

#letter-root > * {
  flex: 1 0 auto;
}

#letter-root .colored {
  background-color: var(--navbar-color);
}

#letter-root .letter-row {
  display: flex;
  flex-direction: row;
  padding: calc(13000vw / 1920) calc(25000vw / 1920) calc(13500vw / 1920)
    calc(24400vw / 1920);
}

#letter-root .letter-row > * {
  flex: 0 0 auto;
}

#letter-root .letter-row .row-title {
  font-family: var(--font-thin);
  font-size: var(--font-size-58);
  letter-spacing: var(--letter-spacing-2-32);
  line-height: var(--line-height-64);
  width: calc(78000vw / 1920);
}

#letter-root .letter-row .row-title sup {
  vertical-align: super;
  font-size: var(--font-size-38);
  line-height: 0;
}

#letter-root .letter-row .content sup {
  vertical-align: super;
  font-size: var(--font-size-16);
  line-height: 0;
}

#letter-root .letter-row .rounded p {
  margin-bottom: 0;
}

#letter-root p {
  margin-top: 0;
}

#letter-root .letter-row div.content {
  width: calc(61200vw / 1920);
  margin-left: calc(10000vw / 1920);
}

#letter-root .numbered-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

#letter-root .button {
  display: block;
  margin-top: calc(3500vw / 1920);
}

#letter-root .separator {
  height: 1px;
  background-color: #707070;
  margin: 0 0 0 calc(24400vw / 1920);
}

#letter-root #popup-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  transition: linear 0.3s;
  opacity: 0;
  visibility: hidden;
}

#letter-root #popup-background.visible {
  visibility: visible;
  opacity: 1;
}
