body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Sen-Regular';
  src: local('Sen-Regular'), url(./fonts/Sen-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Sen-Bold';
  src: local('Sen-Bold'), url(./fonts/Sen-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Italic';
  src: local('Poppins-Italic'), url(./fonts/Poppins-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: local('Poppins-ExtraBold'), url(./fonts/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins-Thin'), url(./fonts/Poppins-Thin.ttf) format('truetype');
}
