#side-menu {
  position: absolute;
  width: 41vw;
  top: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.8;
  font-family: var(--font-regular);
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-0-2);
  line-height: var(--line-height-30);
  margin: 0;
  padding: 0;
  transition: right 0.4s;
  overflow-y: hidden;
  user-select: text;
}

#side-menu.collapsed {
  right: -36vw;
}

#side-menu.expanded {
  right: 0;
}

.button-label {
  font-family: var(--font-medium);
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-0-64);
  line-height: var(--line-height-30);
}

.button-image {
  width: 2.292vw;
  height: 2.292vw;
}

#side-menu #close-button {
  align-self: center;
  width: 100%;
  margin-top: 2vw;
  color: black;
}

#side-menu #close-button:hover {
  color: var(--main-color);
  border-color: var(--main-color);
  transition: linear 250ms;
}

#side-menu #close-button:active {
  color: var(--main-color-darker);
  border-color: var(--main-color-darker);
  transition: linear 250ms;
}

#side-menu .button-panel {
  width: 5vw;
  height: 100%;
  float: left;
}

#side-menu .content {
  padding: 3.5vw 4.7vw 68px 1.3vw;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
}

#side-menu p {
  margin-top: 0;
}

#side-menu .content > div {
  padding: calc(15vw * 100 / 1920) 0;
}

#side-menu h1,
h2,
h3,
h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}

#side-menu h1 {
  font-family: var(--font-thin);
  font-size: var(--font-size-58);
  letter-spacing: var(--letter-spacing-2-32);
  line-height: var(--line-height-64);
}

#side-menu h2 {
  font-family: var(--font-medium);
  font-size: var(--font-size-30);
  letter-spacing: var(--letter-spacing-0-3);
  line-height: var(--line-height-36);
}

#side-menu h3 {
  font-family: var(--font-regular);
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-0-2);
  line-height: var(--line-height-30);
}

#side-menu h4 {
  font-family: var(--font-extrabold);
  font-size: var(--font-size-24);
  letter-spacing: var(--letter-spacing-1-32);
  line-height: var(--line-height-30);
}

#side-menu .content > .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#side-menu hr {
  width: 100%;
  border-top: 1px solid gray;
}

#side-menu sup {
  vertical-align: super;
  font-size: var(--font-size-16);
  line-height: 0;
}
