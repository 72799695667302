#blocking-view {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    opacity: 0.6;
}

#loading-parent {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#loading-title {
    font-family: var(--font-medium);
    font-size: var(--font-size-16);
    color: gray;
    margin-bottom: 0.8vw;
    text-align: center;
}

#loading-parent .horiz-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10vh;
}

@media only screen 
  and (max-device-width: 900px) 
  and (orientation: landscape) { 
    #loading-parent .horiz-text {
        margin-bottom: 5vh;
    }
}


#loading-parent .left-text-1 {
    font-family: var(--font-medium);
    font-size: var(--font-size-58);
    line-height: var(--line-height-64);
    margin-right: 20vw;
}

#loading-parent .right-text-1 {
    font-family: var(--font-regular);
    font-size: var(--font-size-24);
    line-height: var(--line-height-34);
    width: 30vw;
}

.bar-main {
    width: 35vw;
    height: 2.3vw;
    border: 1px solid var(--main-color);
    border-radius: 2.3vw;
    margin: 0 auto;
}

.loading-fade-out {
    animation: loading-fade-out 1s linear 0.5s;
    animation-fill-mode: forwards;
}
  
@keyframes loading-fade-out {
    from { opacity: 1; }
    to { opacity: 0; visibility: hidden; }
}

#loading-parent .note {
    font-size: 0.8em;
}