.grecaptcha-badge {
  display: none !important;
}

#news-letter-root {
  padding: 17vh 0 17vh 0;
  margin-top: auto;
  width: 100%;
  bottom: 0;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: -3px -3px 6px #00000029;
}

#news-letter-root .content {
  position: relative;
  top: 20%;
  bottom: 20%;
  left: 25%;
  right: 25%;
  width: 50%;
}

#news-letter-root .title {
  text-align: center;
  font: normal normal 200 var(--font-size-70)/var(--line-height-74) var(--font-thin);
  letter-spacing: 7px;
  color: #000000;
  text-transform: uppercase;
}

#news-letter-root .form {
  margin-top: 10%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: calc(2000vh / 1080);
  grid-row-gap: calc(2000vh / 1080);
}

#news-letter-root .required {
  color: #c60000ed;
}

#news-letter-root .form-div > textarea,
.form-div > input {
  resize: none;
  width: 100%;
  height: calc(100% - calc(4000vh / 1080));
  font-family: var(--font-regular);
  font-size: var(--font-size-24);
  line-height: var(--line-height-34);
  box-sizing: border-box;
  padding: calc(1000vh / 1080);
}

#news-letter-root .form-div > textarea:focus,
.form-div > input:focus {
  outline: none !important;
  border: 1px solid var(--main-color);
  box-shadow: 0 0 10px var(--main-color);
}

#news-letter-root .form-div > h2 {
  text-transform: uppercase;
  margin-bottom: calc(500vw / 1920);
  font-family: var(--font-medium);
  font-size: var(--font-size-17);
  letter-spacing: calc(24vh / 1080);
  line-height: var(--line-height-30);
}

#news-letter-root .form-div.one {
  grid-area: 1 / 1 / 2 / 2;
}

#news-letter-root .form-div.two {
  grid-area: 1 / 2 / 2 / 3;
}

#news-letter-root .form-div.three {
  grid-area: 2 / 1 / 3 / 3;
}

#news-letter-root .form-div.four {
  grid-area: 3 / 1 / 5 / 3;
}

#news-letter-root .buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: calc(2000vh / 1080);
}

#news-letter-root .label {
  position: absolute;
  left: 0;
  margin-left: calc(6500vh / 1080);
}

#news-letter-root .buttons > .policy {
  display: flex;
  flex-direction: row;
  left: 0;
}

#news-letter-root .buttons > .policy > .policy-text {
  text-align: left;
  font: normal normal normal var(--font-size-20)/var(--line-height-30) var(--font-regular);
  letter-spacing: 0.2px;
  color: #000000;
}

#news-letter-root .checkbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px #00000029;
  border: 2px solid #707070;
  border-radius: 5px;
  margin-right: 8px;
  width: calc(2600vw / 1920);
  height: calc(2600vw / 1920);
  flex-shrink: 0;
}

#news-letter-root .rounded.button.disabled {
  color: #00000029;
  border: calc(400vw / 1920) solid #00000029;
  cursor: default;
}

#news-letter-root .rounded.button.disabled:hover {
  color: #00000029;
  border: calc(400vw / 1920) solid #00000029;
}

#news-letter-root .rounded.button.disabled:active {
  color: #00000029;
  border: calc(400vw / 1920) solid #00000029;
}

#news-letter-root .status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  transition: linear 1s;
  text-transform: uppercase;
  font-family: var(--font-medium);
  font-size: var(--font-size-40);
  letter-spacing: calc(232vh / 1080);
  line-height: var(--line-height-48);
  background-color: #f8f8f8;
}

#news-letter-root .status .status-text {
  text-align: center;
  margin: 0 8vw 0 8vw;
}

#news-letter-root .status > * {
  margin: calc(2000vh / 1080);
}

#news-letter-root .buttons .rounded.button {
  padding: calc(1000vh / 1080) calc(4000vh / 1080);
  margin-left: calc(6500vh / 1080);
  margin-top: 0;
}

#news-letter-root a {
  color: var(--main-color);
  text-decoration: underline;
}
