html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

body {
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
}

#world-comp {
  position: absolute;
  top: 8.646vw;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

/* LINKS */

a:-webkit-any-link {
  text-decoration: none;
}

/* FONTS */

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

:root {
  --font-regular: "Poppins-Regular", sans-serif;
  --font-medium: "Poppins-Medium", sans-serif;
  --font-semibold: "Poppins-Semibold", sans-serif;
  --font-italic: "Poppins-Italic", sans-serif;
  --font-thin: "Poppins-Thin", sans-serif;
  --font-extrabold: "Poppins-ExtraBold", sans-serif;
  --font-size-16: calc(6px + 10vw * 100 / 1920);
  --font-size-17: calc(6px + 11vw * 100 / 1920);
  --font-size-20: calc(6px + 14vw * 100 / 1920);
  --font-size-22: calc(6px + 16vw * 100 / 1920);
  --font-size-24: calc(6px + 18vw * 100 / 1920);
  --font-size-26: calc(6px + 20vw * 100 / 1920);
  --font-size-30: calc(6px + 24vw * 100 / 1920);
  --font-size-38: calc(6px + 32vw * 100 / 1920);
  --font-size-40: calc(6px + 34vw * 100 / 1920);
  --font-size-43: calc(6px + 37vw * 100 / 1920);
  --font-size-46: calc(6px + 40vw * 100 / 1920);
  --font-size-48: calc(6px + 42vw * 100 / 1920);
  --font-size-50: calc(6px + 44vw * 100 / 1920);
  --font-size-58: calc(0px + 58vw * 100 / 1920);
  --font-size-70: calc(6px + 64vw * 100 / 1920);
  --font-size-100: calc(6px + 94vw * 100 / 1920);
  --font-size-110: calc(6px + 104vw * 100 / 1920);
  --letter-spacing-0-16: calc(0.16vw * 100 / 1920);
  --letter-spacing-0-2: calc(0.2vw * 100 / 1920);
  --letter-spacing-0-24: calc(0.24vw * 100 / 1920);
  --letter-spacing-0-26: calc(0.26vw * 100 / 1920);
  --letter-spacing-0-3: calc(0.3vw * 100 / 1920);
  --letter-spacing-0-3: calc(0.43vw * 100 / 1920);
  --letter-spacing-0-64: calc(0.64vw * 100 / 1920);
  --letter-spacing-1-04: calc(1.04vw * 100 / 1920);
  --letter-spacing-1-32: calc(1.32vw * 100 / 1920);
  --letter-spacing-1-52: calc(1.52vw * 100 / 1920);
  --letter-spacing-1-84: calc(1.84vw * 100 / 1920);
  --letter-spacing-2-32: calc(2.32vw * 100 / 1920);
  --letter-spacing-2-8: calc(2.8vw * 100 / 1920);
  --letter-spacing-4: calc(4px);
  --line-height-20: calc(5px + 15vw * 100 / 1920);
  --line-height-26: calc(5px + 21vw * 100 / 1920);
  --line-height-30: calc(5px + 25vw * 100 / 1920);
  --line-height-34: calc(5px + 29vw * 100 / 1920);
  --line-height-36: calc(5px + 31vw * 100 / 1920);
  --line-height-40: calc(5px + 35vw * 100 / 1920);
  --line-height-48: calc(5px + 43vw * 100 / 1920);
  --line-height-52: calc(5px + 47vw * 100 / 1920);
  --line-height-53: calc(5px + 48vw * 100 / 1920);
  --line-height-56: calc(5px + 50vw * 100 / 1920);
  --line-height-60: calc(5px + 55vw * 100 / 1920);
  --line-height-64: calc(5px + 59vw * 100 / 1920);
  --line-height-70: calc(5px + 65vw * 100 / 1920);
  --line-height-74: calc(5px + 69vw * 100 / 1920);
  --line-height-94: calc(5px + 89vw * 100 / 1920);
  --line-height-108: calc(5px + 103vw * 100 / 1920);
}

/* COLORS */

:root {
  --main-color: #82b6b8;
  --main-color-darker: #5d979a;
  --navbar-color: #f3f1f0;
}

/* ANIMATION */

:root {
  --move-delay: 0s;
  --move-fade-out-time: 0.1s;
  --move-hold-time: 0.3s;
  --move-fade-in-time: 0.5s;

  --zoom-in-delay: 1.3s;
  --zoom-in-fade-out-time: 0.9s;
  --zoom-in-hold-time: 0.1s;
  --zoom-in-fade-in-time: 0.5s;

  --zoom-out-delay: 0.3s;
  --zoom-out-fade-out-time: 0.1s;
  --zoom-out-hold-time: 0.3s;
  --zoom-out-fade-in-time: 0.5s;
}

.button {
  color: black;
  outline: none;
  transition: linear 250ms;
  cursor: pointer;
}

.rounded.button {
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0);
  border: calc(400vw / 1920) solid black;
  padding: calc(1000vw / 1920) calc(4000vw / 1920);
  font-family: var(--font-extrabold);
  font-size: var(--font-size-24);
  letter-spacing: var(--letter-spacing-0-24);
  line-height: var(--line-height-34);
  height: max-content;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
}

.text.button {
  text-decoration: underline;
}

.button:hover,
.button:visited:hover {
  color: var(--main-color);
  border-color: var(--main-color);
  transition: linear 250ms;
}

.button:active {
  color: var(--main-color-darker);
  border-color: var(--main-color-darker);
}

.button:visited {
  color: black;
  border-color: black;
}

#portrait-turn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
  background-color: var(--navbar-color);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: linear 0.5s;
}

#portrait-turn > #logo {
  margin: 5vh 5vw auto 5vw;
  max-height: 20vh;
  object-fit: contain;
  overflow: hidden;
}

#portrait-turn > #image {
  max-width: 35%;
  max-height: 35%;
}

#portrait-turn > p {
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-family: var(--font-medium);
  font-size: var(--font-size-100);
  letter-spacing: var(--letter-spacing-0-24);
  margin-bottom: auto;
}

.superscript {
  position: relative;
  top: -0.5em;
  font-size: 0.7em;
}

@media only screen and (orientation: landscape) {
  #portrait-turn {
    visibility: hidden;
    opacity: 0;
  }
}

@media only screen and (orientation: portrait) {
  #portrait-turn {
    visibility: visible;
    opacity: 1;
  }
}

@media print {
  #portrait-turn {
    display: none !important;
  }
}
