#navigation-panel {
  position: absolute;
  top: 2.2vw;
  left: 0;
  right: 0;
  height: 8.646vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 3.385vw 0 0;
}

#logo-img {
  width: 8.5vw;
}

.navigation-container .logo {
  position: absolute;
  left: 1.771vw;
  top: 6.146vw;
  width: 8.5vw;
  cursor: pointer;
}

.container-shadow {
  -webkit-filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.16));
}

.navigation-container {
  clip-path: polygon(0 0, 100vw 0, 100vw 8.646vw, 14.3vw 8.646vw, 12.0vw 13.049vw, 0 13.049vw);
  -webkit-clip-path: polygon(0 0, 100vw 0, 100vw 8.646vw, 14.3vw 8.646vw, 12.0vw 13.049vw, 0 13.049vw);
  height: 13.049vw;
  background-color: var(--navbar-color);
}

#navigation-panel .button {
  font-family: var(--font-medium);
  font-size: var(--font-size-22);
  letter-spacing: var(--letter-spacing-1-32);
  line-height: var(--line-height-26);
  color: darkgray;
  margin: 0 0 0 2.865vw;
  text-decoration: none;
}

#navigation-panel .button:hover {
  background-color: transparent;
  color: var(--main-color);
  border-color: var(--main-color);
  transition: linear 250ms;
}

#navigation-panel .button:active {
  background-color: transparent;
  color: var(--main-color-darker);
  border-color: var(--main-color-darker);
  transition: linear 250ms;
}

#navigation-panel .selected {
  text-decoration: underline;
  text-underline-offset: 0.521vw;
  text-decoration-thickness: 2px;
  color: black;
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5vw;
  background-color: var(--main-color-darker);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.banner > p {
  font-family: var(--font-medium);
  font-size: var(--font-size-16);
  letter-spacing: var(--letter-spacing-0-24);
  color: white;
  margin: 0;
}

.banner > p > a {
  color: white;
  text-decoration: underline;
}
