#movement-panel {
    position: absolute;
    left: 3.646vw;
    bottom: 4.604vw;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

#movement-controls {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
}

#movement-panel .button-icon {
    width: 2.292vw;
    height: 2.292vw;
    border-radius: 2.604vw;
    border: 2px solid black;
    padding: 2px;
}

#movement-panel .controls-button-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-medium);
    font-size: var(--font-size-16);
    letter-spacing: var(--letter-spacing-0-64);
    line-height: var(--line-height-30);
}

#movement-panel .vertical-line {
    background-color: black;
    width: 1px;
    height: 2.604vw;
    margin: auto 1.667vw 0 1.667vw;
}

#movement-panel .horizontal-line {
    background-color: black;
    height: 1px;
    margin: 0.990vw 0 0.781vw 0;
}

#movement-panel .button-icon:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    transition: linear 250ms;
}

#movement-panel .button-icon:active {
    color: var(--main-color-darker);
    border-color: var(--main-color-darker);
    transition: linear 250ms;
}

#movement-panel .text-comp {
    font-family: var(--font-regular);
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-0-2);
    line-height: var(--line-height-30);
    text-align: center;
    margin: 0;
}

#movement-panel #popup-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    transition: linear 0.3s;
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;
}

#movement-panel #popup-background.visible {
    visibility: visible;
    opacity: 1;
}

#pin-index {
    width: 4.531vw;
    text-align: center;
    align-self: center;
    margin: auto 0 0.8vw 0;
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-0-2);
    line-height: var(--line-height-30);
    font-family: 'Sen-Regular';
}

#movement-panel .button.feature {
    background: none;
    border: none;
    font-family: var(--font-regular);
    font-size: var(--font-size-20);
    letter-spacing: var(--letter-spacing-0-2);
    line-height: var(--line-height-30);
    text-align: center;
}