.center {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
}

.circle-shadow {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.16);
}
  
.circle {
    background: var(--main-color);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    opacity: 0.7;
    overflow: visible;
}
  
.pulse {
    -webkit-animation: pulse-animation 2s infinite;
    animation: pulse-animation 2s infinite;
}
  
@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}