#about-root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  overflow-y: auto;
  font-family: var(--font-regular);
  font-size: var(--font-size-24);
  letter-spacing: var(--letter-spacing-0-24);
  line-height: var(--line-height-34);
  background-color: white;
  user-select: text;
}

#about-root .main-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: 8.049vw;
  margin-top: -8.049vw;
  background: white
    linear-gradient(
      319deg,
      #e9d8eb66 0%,
      #e2d7e93d 0%,
      #e7d8ea66 0%,
      #e9d8eb8d 31%,
      #79c4c712 60%,
      #ffffff00 100%,
      #e9d8eb66 100%
    )
    0% 0% no-repeat padding-box;
}

#about-root .newsletter-row {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-content: center;
  align-items: center;
  height: max-content;
}

#about-root .about-row {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: flex-start;
  margin: 9vw 13vw 0vw 13vw;
}

#about-root .about-row > * {
  flex-basis: 0;
  flex-grow: 1;
}

#about-root .about-row.colored {
  background-color: #f8f8f8;
  box-shadow: -3px -3px 6px #00000029;
}

#about-root .about-row .title {
  font-family: var(--font-thin);
  font-size: var(--font-size-70);
  letter-spacing: var(--letter-spacing-4);
  line-height: var(--line-height-74);
  /*text-transform: uppercase;*/
}

#about-root .main-content .title sup {
  vertical-align: super;
  font-size: var(--font-size-48);
  line-height: 0;
}

#about-root .main-content .text sup {
  vertical-align: super;
  font-size: var(--font-size-16);
  line-height: 0;
}

#about-root .mid-content .title sup {
  vertical-align: super;
  font-size: var(--font-size-30);
  line-height: 0;
}

#about-root .mid-content .text sup {
  vertical-align: super;
  font-size: var(--font-size-16);
  line-height: 0;
}

#about-root .about-row .inside-text-button {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  color: var(--main-color);
  cursor: pointer;
}

#about-root .about-row .inside-text-button:focus {
  border: none;
  outline: none;
}

#about-root .mid-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 45vw;
  margin: 3vw auto 18vw auto;
}

#about-root .mid-content .mid-content-row {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#about-root .mid-content .title {
  margin: 5vw 0 1vw 0;
  font-family: var(--font-extrabold);
  font-size: var(--font-size-46);
  line-height: var(--line-height-60);
}

#about-root #popup-background {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  transition: linear 0.3s;
  opacity: 0;
  visibility: hidden;
}

#about-root #popup-background.visible {
  visibility: visible;
  opacity: 1;
}

#about-root #popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 3px 6px #00000029;
  padding: calc(5900vw / 1920) calc(4900vw / 1920) calc(3500vw / 1920)
    calc(8100vw / 1920);
  border-radius: 8px;
  width: calc(55000vw / 1920);
  font-family: var(--font-regular);
  font-size: var(--font-size-24);
  letter-spacing: var(--letter-spacing-0-24);
  line-height: var(--line-height-34);
}

#about-root #popup strong {
  font-weight: normal;
  font-family: var(--font-semibold);
}

#about-root #popup-button {
  display: block;
  margin-top: 0;
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
}

#about-root #popup-button:hover {
  color: var(--main-color);
  border-color: var(--main-color);
}

#about-root #popup-button:focus {
  outline: none;
}

#about-root #popup-button:active {
  color: var(--main-color-darker);
  border-color: var(--main-color-darker);
  outline: none;
}

#about-root .text a {
  text-decoration: none;
  color: var(--main-color);
}

#about-root .text a:hover {
  text-decoration: none;
  color: var(--main-color);
}

#about-root .text a:active {
  text-decoration: none;
  color: var(--main-color-darker);
}

#about-root .rounded.button {
  display: inline-block;
  flex: 1 1 auto;
  margin-top: 0.5vw;
}

#about-root p {
  margin-top: 0;
  margin-bottom: var(--font-size-24);
}
