#members-root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  user-select: text;
}

#members-root .members-row > * {
  flex: 1 0 auto;
}

#members-root .members-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  padding: calc(18700vw / 1920) calc(19000vw / 1920) calc(10900vw / 1920)
    calc(10000vw / 1920);
}

#members-root #coalition-text {
  padding: 0 0 0 1.5vw;
  font-family: var(--font-regular);
  font-size: var(--font-size-43);
  line-height: var(--line-height-56);
  flex: 1 1 auto;
}

#members-root #coalition-text sup {
  vertical-align: super;
  font-size: var(--font-size-30);
  line-height: 0;
}

#members-root .members-row > * {
  margin: 0;
}

#members-root .members-row #partners {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: flex-start;
  --gap: calc(11000vw / 1920);
  margin: calc(-1 * var(--gap)) 0 0 0;
  width: calc(100% - 50100vw / 1920);
  padding-top: calc(5500vw / 1920);
}

#members-root .members-row #partners img {
  width: calc(23000vw / 1920);
  height: calc(11000vw / 1920);
  margin: var(--gap) 0 0 0;
  margin-right: calc(9500vw / 1920);
  object-fit: contain;
  -webkit-filter: grayscale(100%) brightness(0%);
  filter: grayscale(100%) brightness(0%);
}

#members-root .members-row .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(48000vw / 1920));
  grid-gap: calc(17500vw / 1920) calc(9500vw / 1920);
  width: calc(170000vw / 1920);
}

#members-root .members-row .cell .border {
  width: 57.5%;
  display: flex;
  border: var(--main-color) calc(400vw / 1920) solid;
  border-radius: 50%;
  margin-bottom: calc(3800vw / 1920);
}

#members-root .members-row .cell img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

#members-root .members-row h1 {
  max-width: calc(38600vw / 1920);
  margin-block-start: 2.5vw;
  margin-right: calc(11500vw / 1920);
  font-family: var(--font-thin);
  font-size: var(--font-size-70);
  letter-spacing: var(--letter-spacing-2-8);
  line-height: var(--line-height-74);
}

#members-root h2 {
  text-transform: uppercase;
  font-family: var(--font-extrabold);
  font-size: var(--font-size-46);
  letter-spacing: var(--letter-spacing-1-84);
  line-height: var(--line-height-52);
}

#members-root h3 {
  margin: calc(2500vw / 1920) 0;
  font-family: var(--font-medium);
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-0-24);
  line-height: var(--line-height-30);
}

#members-root h4 {
  font-family: var(--font-regular);
  font-size: var(--font-size-20);
  letter-spacing: var(--letter-spacing-0-24);
  line-height: var(--line-height-30);
}

#members-root sup {
  vertical-align: super;
  font-size: var(--font-size-16);
  line-height: 0;
}

#members-root .separator {
  height: 1px;
  background-color: black;
  margin: 0 80px;
}
