#simple-popup #popup-background {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  transition: linear 0.3s;
  opacity: 0;
  visibility: hidden;
}

#simple-popup #popup-background.visible {
  visibility: visible;
  opacity: 1;
}

#simple-popup #popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 3px 6px #00000029;
  padding: calc(5900vw / 1920) calc(4900vw / 1920) calc(3500vw / 1920)
  calc(8100vw / 1920);
  border-radius: 8px;
  width: calc(55000vw / 1920);
  font-family: var(--font-regular);
  font-size: var(--font-size-24);
  letter-spacing: var(--letter-spacing-0-24);
  line-height: var(--line-height-34);
}

#simple-popup #popup strong {
  font-weight: normal;
  font-family: var(--font-semibold);
}

#simple-popup #popup-button {
  display: block;
  margin-top: 0;
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
}

#simple-popup #popup-button:hover {
  color: var(--main-color);
  border-color: var(--main-color);
}

#simple-popup #popup-button:focus {
  outline: none;
}

#simple-popup #popup-button:active {
  color: var(--main-color-darker);
  border-color: var(--main-color-darker);
  outline: none;
}

#simple-popup .logos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 60px;
  margin-bottom: 60px;
}
#simple-popup .logos img{
  display: block;
  width: 100%;
  height: auto;
}

#simple-popup p > a {
  text-decoration: none;
  color: var(--main-color-darker);
}

#simple-popup p > a:hover {
  text-decoration: none;
  color: var(--main-color);
}

#simple-popup p > a:active {
  text-decoration: none;
  color: var(--main-color-darker);
}

@media only screen and (max-width: 600px) {
  #simple-popup #popup {
    box-sizing: border-box;
    padding: 30px;
    width: 80%;
    text-align: center;
    font-size: 100%;
    line-height: 130%;
  }
  #simple-popup .logos {
    margin-bottom: 30px;
  }
  #simple-popup .logos img{
    margin: auto;
  }
}
