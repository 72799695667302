#curtain {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    opacity: 0;
}

.screen-change-zoom-out {
    animation-name: fade-out, fade-in;
    animation-delay: calc(var(--zoom-out-delay)), calc(var(--zoom-out-delay) + var(--zoom-out-fade-out-time) + var(--zoom-out-hold-time));
    animation-duration: var(--zoom-out-fade-out-time), var(--zoom-out-fade-in-time);
    animation-fill-mode: forwards, forwards;
}

.screen-change-zoom-in {
    animation-name: fade-out, fade-in;
    animation-delay: calc(var(--zoom-in-delay)), calc(var(--zoom-in-delay) + var(--zoom-in-fade-out-time) + var(--zoom-in-hold-time));
    animation-duration: var(--zoom-in-fade-out-time), var(--zoom-in-fade-in-time);
    animation-fill-mode: forwards, forwards;
}

.screen-change-move {
    animation-name: fade-out, fade-in;
    animation-delay: calc(var(--move-delay)), calc(var(--move-delay) + var(--move-fade-out-time) + var(--move-hold-time));
    animation-duration: var(--move-fade-out-time), var(--move-fade-in-time);
    animation-fill-mode: forwards, forwards;
}

@keyframes fade-out {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-in {
    from { opacity: 1; }
    to { opacity: 0; }
}
