#footer-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F6F4F4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 calc(10800vw / 1920) 0 0;
    box-shadow: 3px -3px 6px #00000029;
    font-family: var(--font-regular);
    font-size: var(--font-size-16);
    letter-spacing: var(--letter-spacing-0-64);
    line-height: var(--line-height-30);
    height: calc(4480vw / 1920);
}

#footer-panel .button {
    flex: 0 0 auto;
    font-family: var(--font-regular);
    font-size: var(--font-size-16);
    letter-spacing: var(--letter-spacing-0-64);
    line-height: var(--line-height-30);
    margin-left: calc(5800vw / 1920);
    background: none;
}

#footer-panel .selected {
    border-bottom: solid 2px black;
    color: black;
}

#footer-panel .leading {
    position: absolute;
    left: calc(7100vw / 1920);
}
