#loading-error {
    background-color: var(--navbar-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 3.646vw;
    bottom: 4.604vw;
    width: 20vw;
}

.error-zoomed-in {
    opacity: 1;
    border: 1px solid red;
}

.error-zoomed-out {
    opacity: 0.6;
    border: none;
}

#loading-error>.close-icon {
    color: red;
    align-self: flex-end;
    cursor: pointer;
    padding: 8px;
}

#loading-error>.error-text {
    color: red;
    text-align: center;
    margin: 0 8px 16px 8px;
    font-family: var(--font-regular);
    font-size: var(--font-size-16);
    line-height: var(--line-height-20);
}